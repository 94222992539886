import axios from "axios";
import React, { useState, useEffect} from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/layout.css";
import "../css/login.css";
import loginss from "./ss.png";
import DeviceDetector from "device-detector-js";
import { Address4 } from "ip-address";
import CryptoJS from 'crypto-js';
export default function Login() {

  const history = useHistory();
  const [Phone, setPhone] = useState();
  // const [Password, setPassword] = useState();
  const [twofactor_code, settwofactor_code] = useState();
  const [otp, setOtp] = useState(false);
  const [secretCode, setSecretCode] = useState();
  const [ipAddress, setIpAddress] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [resendOtp, setResendOtp] = useState(true); 
  const [timer, setTimer] = useState(60); 
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [referral, setReferral] = useState(
    useLocation().pathname.split("/")[2]
  );


  useEffect(() => {
    // Get IP address
    axios.get('https://api.ipify.org?format=json')
      .then(response => {
        const ip = response.data.ip;
        const address = new Address4(ip);
        setIpAddress(address.address);
        

      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });

    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    const device = deviceDetector.parse(userAgent);
    setDeviceName(device.device.model || "Unknown Device");

  }, []);


  useEffect(() => {
    let countdown;
    if (!resendOtp) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(countdown);
            setResendOtp(true); 
            return 60; 
          }
          return prevTimer - 1;
        });
      }, 1000); 
    }
    return () => clearInterval(countdown); 
  }, [resendOtp]);


  // const handleClick = async (e) => {
  //   e.preventDefault();
  //   setIsButtonDisabled(true);
  //   const PhoneTelNum = Phone;
  //   if (!PhoneTelNum) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "Please enter your phone",
  //     });
  //     setIsButtonDisabled(false);
  //   } else if (Phone.length !== 10) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "Please enter a correct phone number",
  //     });
  //     setIsButtonDisabled(false);
  //   } else {
  //     try {
  //       const generateRandomAlphanumeric = (length) => {
  //         const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  //         let result = '';
  //         const charactersLength = characters.length;
  //         for (let i = 0; i < length; i++) {
  //           result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //         }
  //         return result;
  //       };
        
  //       const key = '0e70bbd9b919b94922f5b1e0394a0e0cfe040a5aecedbb8bbed536df5d64cf0e';
  //       const iv = '249ab4766ad60582c2b23c599a331a91'; 
  //       const data = JSON.stringify({ PhoneTelNum, referral, ipAddress, deviceName });
  //       const encryptedData = CryptoJS.AES.encrypt(data, CryptoJS.enc.Hex.parse(key), {
  //         iv: CryptoJS.enc.Hex.parse(iv),
  //         mode: CryptoJS.mode.CBC,
  //         padding: CryptoJS.pad.Pkcs7,
  //       }).toString();
       
  //       const response = await axios.post('https://xvptpd.rkbattle.com/finish-somthing-login', {
  //         encryptedData
  //       });

      
  //       const { status, msg, secret } = response.data;
  //       if (status === 101) {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: msg,
  //         });
  //         setIsButtonDisabled(false);
  //       } else if (status === 200) {
  //         setOtp(true);
  //         setSecretCode(secret);
  //       }
  //     } catch (e) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Something went wrong",
  //       });
  //       console.error(e);
  //       setIsButtonDisabled(false);
  //     }
  //   }
  // };

  const handleClick = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    const PhoneTelNum = Phone;

    if (!PhoneTelNum) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter your phone",
      });
      setIsButtonDisabled(false);
    } else if (Phone.length !== 10) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a correct phone number",
      });
      setIsButtonDisabled(false);
    } else {
      try {
        const key = '0e70bbd9b919b94922f5b1e0394a0e0cfe040a5aecedbb8bbed536df5d64cf0e';
        const iv = '249ab4766ad60582c2b23c599a331a91'; 
        const data = JSON.stringify({ PhoneTelNum, referral, ipAddress, deviceName });
        const encryptedData = CryptoJS.AES.encrypt(data, CryptoJS.enc.Hex.parse(key), {
          iv: CryptoJS.enc.Hex.parse(iv),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }).toString();

        const response = await axios.post('https://xvptpd.rkbattle.com/finish-somthing-login', {
          encryptedData
        });

        const { status, msg, secret } = response.data;
        if (status === 101) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: msg,
          });
          setIsButtonDisabled(false);
        } else if (status === 200) {
          setOtp(true);
          setSecretCode(secret);
          setResendOtp(false); // Disable resend OTP button
          setTimer(60); // Reset timer to 60 seconds
        }
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please Try Again..",
        });
        console.error(e);
        setIsButtonDisabled(false);
      }
    }
  };
 
 
  // const varifyOtp = async (e) => {
  //   // debugger;
  //   e.preventDefault();
  //   console.log("verify otp sumbut req");
  //   if (!Phone) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "Please enter your phone number",
  //     });
  //   } else {
  //     await axios
  //       .post(`https://xvptpd.rkbattle.com/login/finish`, {
  //         Phone,
  //         twofactor_code,
  //         referral,
  //         secretCode,
  //       })
  //       .then((respone) => {
  //         if (respone.data.status == 101) {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Oops...",
  //             text: respone.data.msg,
  //           });
  //         } else if (respone.data.status == 200) {
  //           const token = respone.data.token;
  //           localStorage.setItem("token", token);
  //           window.location.reload(true);
  //           setTimeout(function () {
  //             history.push("/Games");
  //           }, 1000);
  //         } else {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Oops...",
  //             text: "Something went wrong!",
  //           });
  //         }
  //       })
  //       .catch((e) => {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: "Something went wrong!",
  //         });
  //       });
  //   }
  // };

 
  const varifyOtp = async (e) => {
    e.preventDefault();
    if (!Phone) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter your phone number",
      });
    } else {
      await axios
        .post(`https://xvptpd.rkbattle.com/login/finish`, {
          Phone,
          twofactor_code,
          referral,
          secretCode,
        })
        .then((respone) => {
          if (respone.data.status == 101) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: respone.data.msg,
            });
          } else if (respone.data.status == 200) {
            const token = respone.data.token;
            localStorage.setItem("token", token);
            window.location.reload(true);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Please Try Again..",
            });
          }
        })
        .catch((e) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please Try Again..",
          });
        });
    }
  };
 
 
  const setError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Invalid Number",
      confirmation: true,
    });
  };





  return (
    <>
      <div
        className="leftContainer"
        style={{ minHeight: "100vh", backgroundColor: "white" }}
      >
        <div className="main-area bg-dark">
          <div style={{ overflowY: "hidden" }}>
            <div className="splash-overlay" />
            <div className="splash-screen animate__bounce infinite ">
              <figure>
                <img width="100%" src={loginss} alt="" />
              </figure>
            </div>
           
            <div
              className="position-absolute center-xy   mx-5 "
              style={{
                top: "30%",
                width: "84%",
                height: "500px",
                zIndex: 9,
                backgroundColor: "#E6E6E6",
                borderRadius: "20px",
              }}
            >
              <div
                style={{
                  color: "#050A30",
                  padding: "8px 0",
                  borderBottom: "2px solid #050A30",
                  backgroundColor: "#f9f9f9",
                  width: "100%",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                }}
                className="d-flex center-xy  font-15 mb-4  "
              >
                {/* <p className='pl-3 text-danger'>जिसके पास पासवर्ड नहीं है वह इस 8233292045 नंबर पर संपर्क करे</p> */}
                Quick Login
              </div>
              <div
                className="bg-whtie  cxy flex-column"
                style={{
                  marginTop: "30px",
                  width: "85%",
                  height: "auto",
                  backgroundColor: "#ffffff",
                  borderRadius: "5px",
                  border: "2px solid black",
                }}
              >
                <div
                  className="input-group mb-2 bg-white"
                  style={{ transition: "top 0.5s ease 0s", top: "5px" }}
                >
                  <div className="input-group-prepend">
                    <div
                      className="input-group-text bg-white"
                      style={{
                        fontSize: "16px",
                        padding: "7px 5px 7px 20px",
                        width: "60px",
                        border: "0px solid #d8d6de",
                      }}
                    >
                      +91
                    </div>
                  </div>
                  <input
                    className="form-control bg-white"
                    name="mobile"
                    type="tel"
                    placeholder="Mobile number"
                    onChange={(e) => {
                      setPhone(e.target.value);
                      if (e.target.value.length > 10) {
                        setError(true);
                      }
                    }}
                    style={{
                      transition: "all 3s ease-out 0s",
                      letterSpacing: "1px",
                      border: "none",
                      fontSize: "1px",
                    }}
                  />
                  
                </div>
              </div>
              
              {otp && (
                <div
                  className="bg-white  cxy flex-column"
                  style={{
                    width: "85%",
                    height: "auto",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    marginTop: "10px",
                    border: "2px solid black",
                  }}
                >
                  <div
                    className="input-group mb-2"
                    style={{ transition: "top 0.5s ease 0s", top: "5px" }}
                  >
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text"
                        style={{
                          width: "60px",
                          backgroundColor: "#ffffff",
                          padding: "7px 5px 7px 20px",
                          border: "0px solid #d8d6de",
                        }}
                      >
                        OTP
                      </div>
                    </div>
                    <input
                      className="form-control"
                      name="password"
                      type="tel"
                      placeholder="Enter OTP"
                      onChange={(e) => settwofactor_code(e.target.value)}
                      style={{
                        transition: "all 3s ease-out 0s",
                        letterSpacing: "4px",
                        borderRadius: "4px",
                        border: "0px solid #d8d6de",
                      }}
                    />
                    {/* <div className="invalid-feedback">Enter a valid mobile number</div> */}
                  </div>
                </div>

                
              )}
              {!otp && (
                <button className="Login-button cxy mt-4" onClick={(e) => handleClick(e)} disabled={isButtonDisabled}> Continue
                </button>
              )}
              {otp && (
                <button
                  className="Login-button cxy mt-4"
                  onClick={(e) => varifyOtp(e)}
                >
                  Verify
                </button>
              )}

            {otp && (
        <button className="resend-otp-button" onClick={(e) => handleClick(e)} disabled={!resendOtp}>
          {resendOtp ? "Resend OTP" : `Resend OTP in ${timer}s`}
        </button>
        )}

              <span
                style={{
                  color: "#050A30",
                  fontWeight: "700",
                  marginBottom: "10px",
                }}
                className="mt-4 "
              >
                Don't have an account? <a href="/register"> Register</a>
              </span>
            </div>
            <div className="login-footer">
              By continuing I agree that Fast battle Pvt. Ltd. may store and
              process my data in accordance with the{" "}
              <Link to="/term-condition">Terms of Use</Link>,{" "}
              <Link to="/PrivacyPolicy">Privacy Policy</Link> and that I am 18
              years or older. I am not playing from Assam, Odisha, Nagaland,
              Sikkim, Meghalaya, Andhra Pradesh, or Telangana.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
